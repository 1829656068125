@import "./mixins";

.bottom_section_container {
    display: flex;

    .bottom_section_item {
        @include white_card;
        flex: 1;
        margin-right: 20px;
    }
    .bottom_section_item:last-of-type {
        margin-right: 0;
    }

    .organization_link_tranfers {
        flex: 0.7;
    }

    .title {
        font-size: 24px;
        margin: 20px 20px 20px 0;
        color: #1e0868;
        font-family: Heebo-Bold;
    }
}
