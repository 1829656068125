.top_chart {
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    margin: 20px 0;
    padding: 20px;
    box-shadow: rgba(54, 54, 54, 0.349) 0px 0px 10px;
}
.top_chart_title_container {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;
    .top_chart_title {
        font-size: 24px;
        color: #1e0868;
        font-family: Heebo-Bold;
    }
    .top_chart_legend {
        margin-right: auto;
        display: flex;
        align-items: center;
        font-family: Heebo-Regular;
        font-size: 14px;
        color: #a3a3a3;

        .tick_mark {
            width: 15px;
            height: 5px;
            margin: 0 20px 0 10px;
        }
    }
}
