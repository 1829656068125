.card_container {
    padding: 20px;
    box-shadow: #00000029 0px 3px 6px;
    border-radius: 10px;
    flex: 1;
    margin: 0 20px 20px 0;

    &:last-child {
        margin-right: 0;
    }

    .data_container {
        font-size: 48px;
        text-align: center;
        display: grid;
    }

    .disappear_data,
    .hover_data {
        transition: opacity 0.3s ease-in-out;
        grid-row: 1;
        grid-column: 1;
    }

    .disappear_data {
        opacity: 1;
        z-index: 1;
    }

    .hover_data {
        opacity: 0;
        z-index: 0;
    }

    &:hover {
        .disappear_data {
            opacity: 0;
            z-index: 0;
        }
        
        .hover_data {
            opacity: 1;
            z-index: 1;
        }
    }

    .sub_title {
        text-align: center;
        font-size: 18px;
    }
}
