@import "./fonts.scss";

.entries_page_container {
    font-size: 20px;
    .entries_filter_container,
    .entries_title_container,
    .entries_table_container {
        margin: 20px auto;
        width: 80vw;
        box-sizing: border-box;
    }
}

.entries_title_container {
    font-size: 1.4em;
    font-weight: bold;
    color: #1e0868;
    font-family: Heebo-Bold;
}

.entries_filter_container {
    padding: 1.8em;
    border-radius: 10px;
    background-color: white;
    border: #dfdee4 solid 1px;
    box-shadow: #00000029 2px 3px 6px;

    display: flex;
    justify-content: space-around;
    select {
        font-size: 20px;
        border: none;
        background-color: white;
        border-bottom: 1px solid #2C2E33;
        color: #2C2E33;
        padding: 2px;
        outline: none;
    }
}

.entries_table_container {
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    border: #dfdee4 solid 1px;
    box-shadow: #00000029 2px 3px 6px;
}

.entries_pager_container {
    width: fit-content;
    margin: 0 auto;
    font-family: Heebo-Light;
}
