.date_range_filter_outer_container {
    height: 0;
    .date_range_filter_container {
        box-shadow: 2px 3px 6px #0000005e;
        background-color: white;
        padding: 10px;
        height: 120px;

        border-radius: 5px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        label {
            color: #2c2e33;
        }

        input {
            border: 0.5px solid #1e0868;

            // width: 140px;
        }
    }
}
