@import "./fonts.scss";

body {
    background-color: #e5ecee;
}

.home_page_container {
    background-color: #e5ecee;
    overflow: scro;
    .home_topbar {
        direction: rtl;
        width: 100%;

        font-family: Heebo-Regular;

        background-color: #2a8cb7;
        .selected {
            border-bottom: 1px solid white;
        }
        .home_topbar_inner_container {
            margin: 0 auto;
            width: 80%;
            display: flex;
            a {
                margin: 20px 30px;
                font-size: 20px;
                float: left;
                color: white;
                text-decoration: none;
                cursor: pointer;

                transition: all 0.3s;
                border-bottom: 1px solid rgba(255, 255, 255, 0);
                &:hover {
                    border-bottom: 1px solid white;
                }
            }

            a:first-of-type {
                margin-right: 0px;
            }

            button {
                font-size: 20px;
                font-family: Heebo-Bold;
                transition: all 0.3s;
                margin: 15px 0;
                margin-right: auto;

                border: none;
                border-radius: 21px;
                padding: 0 20px;
                color: #2a8cb7;
                background: white;
                cursor: pointer;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}
