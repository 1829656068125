.custom_legend_root_container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.custom_legend_item_container {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
