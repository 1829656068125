@import "./fonts.scss";

.distribution_by_language_container {
    font-family: Heebo-Bold;
    height: 80%;

    .language_stat_container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        margin: 0 10px;
        .language_container {
            display: flex;
            align-items: center;
            .text_container {
                flex-basis: 100px;
                font-size: 18px;
                display: flex;
                justify-content: space-around;
            }
            .count_bar {
                flex: 1;
                margin: 0 10px;
                height: 30px;
                .bar{
                    height:100%;
                }
            }
        }
    }
}
