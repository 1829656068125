@import "./mixins";

.middle_section_container {
    width: 100%;
    margin-bottom: 20px;

    direction: rtl;
    display: flex;
    flex-direction: row;

    .middle_section_item {
        @include white_card;
        margin-left: 10px;
    }

    .middle_section_item:last-of-type {
        margin-left: 0;
    }

    .finished_container {
        flex: 4;
    }

    .age_container {
        flex: 4;
        .distribution_by_age_title {
            @include white_card_title;
        }
    }

    .risk_level_container {
        flex: 5;
    }

    .custom_material_ui_root {
        display: flex;
        padding: 0;
        box-sizing: border-box;
        font-weight: 300;
        flex-direction: column;
    }
}
