$bg: rgb(236, 236, 236);

.entry_center_table_containter {
    color: #2e294e;
    font-size: 0.8em;
}

// .entry_center_table_content{
//     height: 500px;
//     overflow: auto;
// }

.entry_center_table_headers {
    background-color: #003c56;
    color: white;
    padding: 14px 36px;
    box-sizing: border-box;
    font-family: Heebo-Medium;
    span{
        margin:14px;
    }
}

.entry_center_table_row {
    width: 100%;
    padding: 14px 36px;
    box-sizing: border-box;
    cursor: pointer;
    font-family: Heebo-Regular;
    span{
        margin:14px;
    }
}

.entry_center_table_row_container {
    border-bottom: $bg 2px solid;
}
