@import "./mixins";

.distribution_by_risk_level_constainer {
    width: 100%;

    .risk_level_title {
        @include white_card_title;
    }

    .risk_level_data_container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .single_risk_level {
            width: 50%;
            box-sizing: border-box;
            padding: 0 3%;
            margin: 7% 0;

            position: relative;

            .count {
                font-family: Heebo-Light;
                font-size: 45px;
            }

            .risk_level_name_container {
                display: flex;
                .risk_level_name {
                    font-family: Heebo-Medium;
                    margin-left: 8px;
                }

                .tooltip_container {
                    background-color: white;
                    border-radius: 10px;
                    filter: drop-shadow(0px 3px 6px #0a2042);
                    position: absolute;
                    left: 120%;
                    top: 0;
                    opacity: 0;
                    z-index: 20;
                    width: 400px;
                    transition: opacity 0.3s ease-in-out;
                    pointer-events: none;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 40px;
                        left: -12px;
                        z-index: 19;
                        border-top: solid transparent 20px;
                        border-bottom: solid transparent 20px;
                        border-left: none;
                        border-right: solid white 12px;
                    }
                }

                .info_icon:hover + .tooltip_container {
                    opacity: 1;
                }
            }
        }
    }
}
