.distribution_by_finished_container {
    width: 100%;
    height: 100%;
    .chart_container {
        height: 45%;
        max-width: 80%;
        margin: auto;
    }

    .legend_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10% 0 20% 0;

        .legend_item {
            height: fit-content;
            padding: 0 10px;
            .count {
                font-size: 45px;
                font-family: Heebo-Light;
            }
            .status {
                font-family: Heebo-Medium;
            }
        }
    }
}
