@import "./fonts.scss";

.questionnaire_details_container {
    padding: 60px 100px;
    font-family: Heebo-Regular;

    .title {
        direction: rtl;
        font-size: 30px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        // font-weight: bold;
        font-family: Heebo-Bold;

        .icon {
            cursor: pointer;
        }
    }

    .questions_container {
        margin-bottom: 20px;
    }
}
