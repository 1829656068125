@mixin white_card {
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    padding: 20px;
}

@mixin white_card_title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #1e0868;
    font-family: Heebo-Bold;
}
