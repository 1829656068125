@import "./fonts.scss";

.login_container {
    direction: rtl;

    width: 30%;
    
    margin: 0 auto 90px auto;
    .input_container,
    .button_container {
        width: 100%;
    }

    h1 {
        font-size: 35px;
        padding-bottom:20px;
        font-family: Heebo-Bold;
    }

    .input_container {
        display: flex;
        flex-direction: column;

        input {
            font-family: Heebo-Regular;
            direction: rtl;
            padding: 10px;
            margin:6px 0;
            font-size: 18px;
            border: none;
            border-bottom:solid 2px rgba(126, 126, 126, 0.637);

            

            outline: none;

            &:focus{
                background-color: rgb(241, 241, 241);
            }

            &::placeholder {
                // direction: rtl !important;
                text-align: right;
            }
        }
    }

    .button_container {
        button {
            font-family: Heebo-Regular;
            width: 100%;
            margin-top: 20px;
            border: none;
            background-color: rgb(69, 164, 241);
            font-size: 26px;
            padding: 8px 12px;
            outline: none;
            border-radius: 6px;
            box-shadow: 0px 3px 6px #00000029;
            color: white;
            cursor: pointer;

            &:hover {
                background-color: rgb(27, 147, 245);
            }
        }
    }
}

.form_container {
    margin: 0 auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    height: 80vh;
    width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login_page_container {
    width: 100vw;
    height: 100vh;
    background-color: rgb(207, 207, 235);
    display: flex;
    align-items: center;
    font-family: Heebo-Regular;
}
