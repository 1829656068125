.white_card_container {
    background-color: white;

    padding: 30px 40px;
    direction: rtl;
    color: #1e0868;
    font-size: 18px;
    border-radius: 7px;
    box-shadow: 2px 3px 6px #00000029;

    .white_card_title {
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 30px;
        margin-right: 50px;
    }

    .question_and_answer_container {
        display: flex;
        border-bottom: 1px solid #00000029;
        padding: 8px;

        .question {
            flex: 3;
        }

        .answer {
            flex: 1;
        }

        .answer, .question {
            padding: 0 50px;
        }
    }

    .question_and_answer_container:last-of-type {
        border-bottom: none;
    }
}
