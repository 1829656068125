@import "./_fonts";

.tooltip_risklevel_container {
    display: flex;
    direction: rtl;
    flex-direction: row;
    padding: 10px;
    font-family: Heebo-Regular;

    align-items: center;
    .risklevel_total {
        font-size: 40px;
        margin:0 20px;
        font-family: Heebo-Light;
    }

    .risklevel_name{
        margin: 5px;
        font-family: Heebo-Bold;

    }

    .risklevel_counts{
        margin:10px 0;
        span{
            margin: 5px;
        }
    }
}
