@import "./fonts.scss";

.statistics_page_container{
    color:#1E0868;
    font-family: Heebo-Regular;
    width: 80%;
    margin: 24px auto;
}
.statistics_title {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5em 1.0em;

    font-family: Heebo-Bold;
    font-size: 20px;
    color:#1E0868;

    direction: rtl;
    h3{
        margin:0 0 10px 0;
    }
}

.statistics_filter_container {
    font-family: Heebo-Bold;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    background: white;
    padding: 0.5em 1.0em;
    border-radius: 5px;
    border: #dfdee4 solid 1px;
    box-shadow: #00000029 2px 3px 6px;

    .mui_select{
        height:45px;
        box-sizing: border-box;
        align-self: center;
    }

    div:nth-of-type(n) {
        margin: 0 12px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    span{
        order:1;
        flex:1;
        text-align: right;
        font-size: 36px;
        text-align: center;
        display: flex;
        flex-direction: row-reverse;
    }
}